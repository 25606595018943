import React, { Component } from "react";

import { FaFacebook, FaLinkedin } from "react-icons/fa";

export default class SocialIcons extends Component {
  state = {
    icons: [
      {
        id: 1,
        label: "INT Facebook Page",
        icon: <FaFacebook className="facebook-icon" />,
        path: "https://www.facebook.com/InlandNorthwestTechnologists/",
      },
      {
        id: 2,
        label: "INT LinkedIn Page",
        icon: <FaLinkedin className="linkedin-icon" />,
        path: "https://www.linkedin.com/company/inlandnorthwesttech/",
      },
    ],
  };
  render() {
    return (
      <div className="navbar-icons">
        {this.state.icons.map((item) => {
          return (
            <a href={item.path} key={item.id} target="_blank" rel="noopener noreferrer" aria-label={item.label}>
              {item.icon}
            </a>
          );
        })}
      </div>
    );
  }
}
