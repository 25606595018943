import React from "react";
import { Link } from "gatsby";

import SocialIcons from "../../components/SocialIcons";

export default function Footer({ footerLocationOne, footerLocationTwo, footerPhone, footerLink, footerCopy, int }) {
  return (
    <footer className="footer-container">
      <address>
        <a href={`tel:${footerPhone}`}>{footerPhone}</a>
        <p>
          {footerLocationOne}
          <br />
          {footerLocationTwo}
        </p>
        <SocialIcons />
      </address>

      <div className="copyright-container">
        <p className="credits">
          Photos courtesy of <a href="https://unsplash.com">Unsplash</a>
        </p>
        <Link to="/code-of-conduct" className="footerLink" aria-label="Read more about our code of conduct">
          {footerLink}
        </Link>
        <p>
          {footerCopy}
          &copy;
          {new Date().getFullYear()}
          {int}
        </p>
      </div>
    </footer>
  );
}
