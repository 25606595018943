import React, { Component } from "react";
import SocialIcons from "../../components/SocialIcons";

import { Link } from "gatsby";

const SiteLinks = [
  {
    path: "/meetups-and-events/",
    name: "Meetups & Events",
  },
  {
    path: "/contact/",
    name: "Contact",
  },
  {
    path: "/donate/",
    name: "Donate",
  },
];

export default class NavbarLinks extends Component {
  render() {
    const { location, toggleNavbar } = this.props;

    return (
      <div id="link-wrapper" className={this.props.navbarOpen ? "isactive" : "isclosed"}>
        {SiteLinks.map((item, index) => {
          return (
            <div className="nav-links" key={index}>
              <Link
                to={item.path}
                className={`nav-link ${location.pathname === item.path ? "active" : ""}`}
                onClick={toggleNavbar}
              >
                {item.name}
              </Link>
            </div>
          );
        })}
        <SocialIcons />
      </div>
    );
  }
}
