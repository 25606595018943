/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Navbar from "./navbar/Navbar.js";
import Footer from "./footer/Footer.js";

import "../sass/index.scss";

export default class Layout extends React.Component {
  render() {
    const { children, location } = this.props;
    return (
      <div className="site-wrapper">
        <Navbar location={location} />
        <TransitionGroup component={null}>
          <CSSTransition classNames="page-animation" timeout={{ enter: 500, exit: 500 }} key={location.pathname}>
            <div id="transitionContent">
              {children}
              <Footer
                footerLocationOne="201 W Riverside Ave #303,"
                footerLocationTwo="Spokane, WA 99201"
                footerPhone="(509) 934-1130"
                footerLink="OUR CODE OF CONDUCT"
                footerCopy="Copyright"
                int=" Inland Northwest Technologists"
              />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}
