import React, { Component } from "react";
import { Link } from "gatsby";

export default class NavbarHeader extends Component {
  render() {
    const { toggleNavbar, navbarOpen } = this.props;
    return (
      <header id="navbar-header" className={!navbarOpen ? "open" : "isactive"}>
        <Link className="int-brand" to="/">
          <div className="code-white" />
          <h1 className="int-brand">INLAND NORTHWEST TECHNOLOGISTS</h1>
        </Link>
        <button className="toggle-icon" onClick={toggleNavbar}>
          <div className="bar bar1" />
          <div className="bar bar2" />
          <div className="bar bar3" />
        </button>
      </header>
    );
  }
}
