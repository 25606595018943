import React, { Component } from "react";
import NavbarHeader from "./NavbarHeader";
import NavbarLinks from "./NavbarLinks";

export default class Navbar extends Component {
  state = {
    navbarOpen: false,
    isNavbarActive: false,
    isNavScrolled: false,
  };

  handleActivateNavbar = () => {
    const { navbarOpen } = this.state;
    this.setState(() => {
      return { navbarOpen: !navbarOpen };
    });
  };
  toggleNavbar = () => {
    const { navbarOpen } = this.state;
    this.setState(() => {
      return { navbarOpen: !navbarOpen };
    });
  };

  handleScroll = () => {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 50;
      if (isTop !== true) {
        this.setState({ isNavScrolled: true });
      } else {
        this.setState({ isNavScrolled: false });
      }
    });
  };

  componentDidMount() {
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isNavScrolled);
  }

  render() {
    const { navbarOpen, isNavScrolled } = this.state;
    const { location } = this.props;
    return (
      <nav id="navbar-container" className={`${isNavScrolled ? "navScrolled" : ""} ${navbarOpen ? "isactive" : ""}`}>
        <NavbarHeader toggleNavbar={this.toggleNavbar} navbarOpen={navbarOpen} />
        <NavbarLinks location={location} navbarOpen={navbarOpen} toggleNavbar={this.toggleNavbar} />
      </nav>
    );
  }
}
